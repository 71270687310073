.Pardon {
    text-align: start;
  }
  
  .Pardon-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Pardon-header-text{
    font-size: calc(10px + 2vmin);
    color: white;
    margin-left: 10px;
  }
  .Pardon-header-p{
    font-size: calc(5px + 2vmin);
    color:white;
    margin-left: 10px;
    margin-right: 10px;
  }
  .Pardon-link {
    color: #61dafb;
    margin-left:10px;
    margin-bottom: 5px;
    font-size: calc(5px + 2vmin);
  }
  img{
    max-width: 20%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    width: 100%;
}
.image-container img {
    width:20%;
    margin: 0;
}
  
  .Pardon-header-p-center {
    text-align: center;
    font-size: calc(5px + 2vmin);
    color: white;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  