.App {
  text-align: start;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-text{
  font-size: calc(10px + 2vmin);
  color: white;
  margin-left: 10px;
}
.App-header-p{
  font-size: calc(5px + 2vmin);
  color:white;
  margin-left: 10px;
  margin-right: 10px;
}
.App-link {
  color: #61dafb;
  margin-left:10px;
  margin-bottom: 5px;
  font-size: calc(5px + 2vmin);
}
.App img{
  max-width: 90%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.App-header-p-center {
  text-align: center;
  font-size: calc(5px + 2vmin);
  color: white;
  margin-left: 10px;
  margin-right: 10px;
}

.app-link-container {
  display: flex;
  align-items: center;  /* Link ve badge aynı hizada */
  gap: 10px;  /* Link ve buton arasında boşluk */
  margin-left: 10px;
  margin-bottom: 5px;
}

.app-link-container .App-link {
  margin: 0;  /* Linkin altında boşluk bırakmamak için */
}

.app-store-badge img {
  width: 90px;  /* Buton boyutu */
  height: auto;
}
